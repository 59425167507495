export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~3],
		"/agent-view/conversations/[conversationId]": [~5],
		"/agent-view/[agentId]": [~4],
		"/auth0/auth": [~10],
		"/auth0/logout": [~11],
		"/auth/error": [6,[2]],
		"/auth/login": [~7,[2]],
		"/auth/logout": [~8,[2]],
		"/auth/token/[token]": [~9,[2]],
		"/evals": [~12],
		"/knowledge": [~13],
		"/knowledge/v2": [14],
		"/quality": [~15],
		"/settings": [~16],
		"/smart-agents": [~17],
		"/testing-suite": [~18],
		"/votc": [19],
		"/xobot": [~20]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';